<template>
  <div class="video-container">
    <iframe 
      type="text/html" 
      src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&modestbranding=1"
      frameborder="0" 
      allowfullscreen
      class="video"
    />
  </div>
</template>

<script>
export default {
  name: 'Video',
  components: {
  }
};
</script>

<style scoped>
  .video {
    width: 100%;
    height: 100%;
  }
  .video-container {
    width: 960px;
    height: 540px;
  }
  @media screen and (max-width: 1200px) {
    .video-container {
      width: 640px;
      height: 360px;
    }
  }
  @media screen and (max-width: 800px) {
    .video-container {
      width: 426px;
      height: 240px;
    }
  }
  @media screen and (max-width: 500px) {
    .video-container {
      width: 304px;
      height: 171px;
    }
  }
</style>
